/* Container for the entire section */
.examples-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 100%;
  margin: 0;
  text-align: center;
}

/* Heading styling */
.examples-heading {
  font-size: 24px;
  margin-bottom: 0;
  line-height: 1.2;
  color: var(--text-primary-color);
  margin-top: 0;
  margin-bottom: 0;
}

/* Slider container to hold only the message list */
.slider-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

/* Message list styling for horizontal layout */
.message-list {
  margin-top: 20px;

  display: flex;
  gap: 15px;
  overflow: hidden;
  max-width: 90%;
  justify-content: center;
}

/* Individual message item styling */
.message-item {
  flex: 0 0 300px;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  min-height: 200px; /* Fixed height for uniformity */
  height: auto;
}

/* Message text styling */
.message-text {
  font-size: 16px;
  color: #333;
}

/* Verdict styling based on status */
.verdict {
  font-size: 16px;
}

.verdict.fraud {
  color: red;
}

.verdict.not-fraud {
  color: green;
}

/* Navigation buttons container below the message list */
.nav-buttons-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

/* Navigation button styling */
.nav-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.nav-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
