
:root{
  padding: 0;
  margin: 0;
  --primary-color: #4caf50;

  --button-bg-primary-color:var(--primary-color);
  --button-bg-secondary-color:#F5F5DC;

  --chat-text-font-size:18px;
  --option-button-font-size:14px;


  --text-primary-color:#000000;
  --text-secondary-color:#484848;

  
  --chat-text-font-size-sm:16px;
  --option-button-font-size-sm:12px;

  --option-button-bg-color:#484848;



  --plain-white-color :white;  
  --bg-color:#f8f9fa;
  --border-color: #ECF0F1;

  background-color: var(--bg-color);

}



/* * {
  font-family: var(--font-family);
} */

.whatsapp-share {
  position: fixed;
  right: 10px; /* Distance from the right edge */
  top: 50%; /* Align vertically to center */
  transform: translateY(-50%); /* Adjusts for the button's height */
  z-index: 1000; /* Ensures it stays on top */
}

.whatsapp-share svg {
  width: 50px;
  height: 50px;
}


.links{
  margin-right: 20px  ;

}
.links a {
  color: var(--text-primary-color);
  text-decoration: none;
  margin-left: 10px;
}

.links a:first-child {
  margin-left: 0;
}


.links a:hover {
  text-decoration: underline var(--button-bg-primary-color);
}



/* App.css */
.app-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  text-align: center;
  justify-content: flex-start; /* Align items from the top */
  align-items: center; /* Center items horizontally */
  width: 100%;

  padding: 0;
  height: auto; /* Make it full height */
  min-height: 0;
  box-sizing: border-box;
  margin-bottom: 0;
  margin-top: 0;



}

.main-section,
.sub-section {
  width: 100%; /* Make each panel full width */




}
.main-section{
  margin-bottom: 20px;
  background-color: var(--bg-color);
  padding-left: 0;
  padding-right: 0;

}
.sub-section{
background-color: white;
margin-bottom: 20px;
padding-top: 20px ;
padding-bottom: 20px ;

}


.main-heading_app_small {
  padding-top:20px ;
  font-size: 30px;
  margin-bottom: 0;
  line-height: 1.2;
  color: var(--text-primary-color);
  margin-top: 0;
  margin-bottom: 0;
  align-items: center; /* Center items horizontally */

}


.rotate-message {
  display: inline-block;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  color: red;
  font-weight: 800;
  text-align: center;
  transform: translateX(-100%); /* Start off-screen to the left */
  opacity: 0; /* Initially invisible */
}

.rotate-message.show {
  transform: translateX(0); /* Bring into view */
  opacity: 1; /* Fade in */
}



.sub-heading {
  font-size: 18px;
  color: var(--text-secondary-color);
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.2;
}

.VerifyPe {
  position: relative;
  display: inline-block;
}

.VerifyPe::after {
  content: '';
  position: absolute;
  bottom: -2px; /* Adjust as needed */
  left: 0;
  width: 0;
  height: 4px; /* Adjust the thickness of the line */
  background-color: Var(--primary-color); /* Change the color of the line */
  animation: underlineAppear 2s forwards; /* Animation properties */
}

@keyframes underlineAppear {
  0% {
      width: 0; /* Start with no underline */
  }
  100% {
      width: 100%; /* End with full underline */
  }
}


.cta-button {
  margin-top: 20px;
  padding: 15px 100px;
  background-color: var(--button-bg-primary-color);
  border: none;
  cursor: pointer;
  color: var(--plain-white-color );
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  font-size: 16px;
  letter-spacing: .58px;
}


.cta-button:hover {
  background-color: var(--button-bg-secondary-color);
  color: var(--text-primary-color);
}


.cta-button:disabled {
  background-color: #d6d6d6;
  color: #a6a6a6;
  cursor: not-allowed;
}

.image {
  width: 70%;
  height: 100%;
  object-fit: cover; 
}

.mission-section {
  margin: 0;
  padding: 0;
  padding-top: 20px;
  background-color: var(--bg-color);
  padding-bottom: 20px;
  /* border: 1px solid red; */

}

.mission {
  margin: 0;
  padding: 0;
  text-align: center;
  padding-bottom: 0;
}

.mission-heading {
  font-size: 20px;
  margin: 0;
  padding: 0;
  color:var(--text-primary-color);

}


.footer {
  background-color: var(--text-primary-color);
  color: var(--plain-white-color );
  text-align: center;
  padding: 15px;
  width: 100%;
  font-size: 0.9rem;
}

/* In your App.css or a separate CSS file */
.favicon {
  width: 30px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin: 0; /* Adjust spacing between favicon and text */
}

.footer p {
  margin: 0;
}
/* Styling for error messages */
.error {
  color: red; /* Red color for error text */
  margin-top: 5px; /* Space between error message and input field/button */
  font-size: 14px; /* Adjust font size as needed */
}

.subscription-section {
  margin: 0 auto;
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  max-width: 100%;
  text-align: center;
  padding-bottom: 50px;
  background-color: white;
  
  
}



.subscription-section div {
  display: flex;
  flex-direction: column; /* Stack input and button vertically by default */
  gap: 10px; /* Space between input and button */
  align-items: center; /* Center align items horizontally */
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  margin: 0 auto; /* Center form horizontally */
}

.subscription-section input[type="email"] {
  padding: 12px;
  font-size: 16px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  width: 100%; /* Full width on smaller screens */
  box-sizing: border-box; /* Ensures padding does not affect width */
}


.back-icon {
  cursor: pointer; /* Makes the icon clickable */
  width: 24px;     /* Adjust the size of the icon */
  height: 24px;    /* Adjust the size of the icon */
  margin-top: 10px;
  margin-left: 30px;
}

.sub-heading-list {
  list-style-type: none; /* Remove default list styles */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  text-align: center; /* Align list items to the left */
}

.sub-heading-list li {
  font-size: 14px; /* Consistent size for list items */
  color: #495057; /* Consistent color for list items */
  margin: 10px 0; /* Space between list items */
  position: relative; /* Allow for pseudo-element styling */
  padding-left: 20px; /* Space for the bullet */
}


@media (max-width: 768px) {  
  :root{
    padding: 0;
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  .whatsapp-share svg {
    width: 50px;
    height: 50px;
  }

  .whatsapp-share {
    position: fixed;
    right: 10px; /* Distance from the right edge */
    top: 90%; /* Align vertically to center */
    transform: translateY(-50%); /* Adjusts for the button's height */
    z-index: 1000; /* Ensures it stays on top */
  }
  

  .logo {
    padding-left: 10px;
  }
 
 
  .main-heading_app_small {
    font-size: 30px;
    margin-bottom: 0;
    line-height: 1.2;
    color: var(--text-primary-color);
    margin-top: 0;
    margin-bottom: 0;
  
  }

  .sub-heading {
    font-size: 14px;
  }
  .sub-heading-list li {
    font-size: 12px;
  }

.image {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}


.back-icon{
  margin-left: 0;

}

}