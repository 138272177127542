/* FraudTrends.css */
.fraud-trends {
  max-width: 1200px;
  margin: 0;
  padding: 10px;
  font-size: 12pt;
  font-style: normal;
}

h1 {
  text-align: center;
  color: var(--text-primary-color);
    margin-bottom: 20px;
    display: block;
    font-weight: 600;
}

.article-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.article-item {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: var(--bg-color);
}

.title {
  color: var(--text-primary-color);
  font-size: 14pt;

  /* font-size: 20px; */
  margin-bottom: 10px;
  display: block;

}

.subtitle {
  /* font-size: 1.2em; */
  color: var(--text-secondary-color);
}

.read-more {
  display: block;
  margin-top: 10px;
  padding: 10px;
  background-color: #ffffff;
  color: var(--primary-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  border: 1px solid #e0e0e0;
  /* font-size: 1em; */
}

.read-more:hover {
  background-color: #d5d5d5;
}

.article-details {
  margin-top: 20px;
}

.images {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
}

.images img {
  width: 300px;
  height: 300px;
  max-width: 100%;
  border-radius: 4px;
  text-align: center;
}

.content p {
  line-height: 1.6;
  margin-bottom: 10px;
}

.meta {
  /* font-size: 0.9em; */
  color: #777;
}

.date, .author {
  display: block;
  margin-top: 5px;
}


.pagination {
  text-align: center;
  margin-top: 20px;
}

.page-button {
  border: 1px solid var(--border-color);
  background-color:var(--bg-color);
  padding: 5px 10px;
  margin: 0 2px;
  cursor: pointer;
}

.page-button.active {
  background-color: var( --button-bg-primary-color);
  color: var(--plain-white-color );
}

.page-button:hover {
  background-color: #e2e2e2;
}