
.game-container {
  text-align: center;
  margin-top: 20px;
  overflow: hidden; /* Disable both horizontal and vertical scrolling */

}

.fixed-area {
  width: 400px; /* Fixed width for the area */
  height: 300px; /* Fixed height */
  display: flex;
  flex: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 auto;
  overflow: hidden; /* Ensure balloon doesn't overflow the area */
}

.balloon {
  width: 70%;
  margin-left: 2  0px;
  height: 100px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: white;
  transition: width 0.3s, height 0.3s;
}

.slider-container {
  height: 100%; /* Full height of the fixed area */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slider {
  writing-mode: bt-lr;
  width: 300px;
  transform: rotate(-90deg);
  appearance: none;
  cursor: pointer;
  position: absolute;
  margin-right: 100px;
}



.slider::-webkit-slider-thumb {
  appearance: none;
  width: var(--thumb-size, 20px); /* Dynamic size */
  height: var(--thumb-size, 20px); /* Dynamic size */
  background: blue; /* Color of the thumb */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  position: relative;
  z-index: 1; /* Ensure it's above the track */
}


.trace {
  width: 20px; /* Width of trace element */
  height: 20px; /* Height of trace element */
  background-color: red; /* Color of trace element */
  position: absolute;
  margin-right: 100px;

}

button {
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  cursor: pointer;
}

button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}


@media (max-width: 768px) {  

  .fixed-area {
    width: 80%; /* Fixed width for the area */
    height: 300px; /* Fixed height */
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0 auto;
    overflow: hidden; /* Ensure balloon doesn't overflow the area */
  }

}