/* Styles for the TopBar component */
/* .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
} */

.top-bar {
    position: fixed; /* Fixed position to keep it at the top */
    top: 0; /* Align it to the top of the viewport */
    left: 0; /* Align it to the left edge of the viewport */
    padding: 5px 10px; /* Adjusted padding */
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background-color: #ffffff;
    color: var(--text-color);
    border-bottom: 0.5px solid #E0E0E0; /* Border color */
    width: 100%; /* Full width */
    white-space: nowrap;
    z-index: 1000; /* Ensure it's above other content */
}

body {
    margin-top: 80px; /* Height of the top bar */
}



.logo {
    display: flex;
    align-items: center; /* Vertically centers the text with the image */
    cursor: pointer; /* Makes it clear it's clickable */
    color: var(--primary-color);
    font-weight: bold; /* Make text bold */
    margin: 0;
    padding: 0;
    margin-left: 15px;

  }
  
  .logo img {
    height: 30px; /* Adjust height as needed */
    width: auto; /* Keeps the aspect ratio */
    margin: 0;
    padding: 0;
    margin-right: 5px;
  
  
  }
  
  .logo span {
    font-size: 1.2em; /* Adjust the text size if needed */
  }
  
.links {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjust the gap between links as needed */
    margin-right: 50px;
}

.links a {
    text-decoration: none;
    background: transparent;
    color: var(--text-primary-color);
    border: none;
    cursor: pointer;
}


.links a:first-child {
    margin-left: 0;
  }
  
  
  .links a:hover {
    text-decoration: underline var(--button-bg-primary-color);
  }
  

/* Styles for the hamburger menu */
.hamburger-menu {
    display: none; /* Hidden by default */
    flex-direction: column;
    cursor: pointer;
    margin: 0;
    padding: 0;
    padding-right: 40px;
}

.hamburger-icon {
    width: 20px;
    height: 3px;
    background-color: var(--primary-color);
    margin: 2px 0;
    padding: 0;
}

/* Show the hamburger menu and hide links on smaller screens */
@media (max-width: 500px) {

    .links {
        display: none; /* Initially hidden */
        position: fixed; /* Fixed to cover the entire screen */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 70%; /* Full width */
        height: 100%; /* Full height to cover the device screen */
        background-color: rgba(0, 0, 0, 0.9); /* Black background with some transparency */
        z-index: 1000; /* Ensure it appears above other content */
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 0.3s ease, transform 0.3s ease;
        pointer-events: auto; /* Allow clicking on links */
    }

    .links a {
        text-decoration: none;
        background: transparent;
        color: white;
        border: none;
        cursor: pointer;
    }

    .links.open {
        display: flex; /* Show the menu when open */
        opacity: 1;
        transform: translateY(0);
    }

    /* Make other content unclickable */
    body.menu-active *:not(.links) {
        pointer-events: none; /* Disable clicking on other elements */
    }

    .hamburger-menu {
        display: flex; /* Show hamburger icon on smaller screens */
        margin: 0;
    }
}
