.container {
  padding: 20px;
}

.containerChatVerify {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  height: auto; /* Full viewport height */
  padding: 20px; /* Optional padding */
  position: relative; /* Allows for relative positioning of child elements */
}


.section_action_container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  gap: 20px; /* Space between the sections */
  height: auto; /* Make the container take full viewport height to center vertically */
}
/* Define the buzz animation */
@keyframes buzz {
  0% { transform: scale(1); }
  10% { transform: scale(1.02); }
  20% { transform: scale(1); }
  30% { transform: scale(1.02); }
  40% { transform: scale(1); }
  50% { transform: scale(1.02); }
  60% { transform: scale(1); }
  70% { transform: scale(1.02); }
  80% { transform: scale(1); }
  90% { transform: scale(1.02); }
  100% { transform: scale(1); }
}


.section_action {
  width: 200px; /* Fixed width */
  height: 150px; /* Fixed height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  text-align: center;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
  border: 5px solid var(--border-color);
  animation: buzz 50s infinite; /* Apply the buzz animation */
}


.section_action h2 {
  font-size: 18px;
  margin: 0;
  margin-bottom: 20px; /* Gap between h2 and p */
  color: var(--primary-color);
}

.section_action p {
  font-size: 14px;
  margin: 0;
  text-align: center; /* Ensure text is center-aligned horizontally */
  color: var(--text-secondary-color);

}

.section_action:hover {
  background-color:  var(--primary-color);
  color: white;
}

.section_action:hover h2{
  color: white; /* Change h2 color on hover */
}

.section_action:hover p {
  color: white; /* Change p color on hover if needed */
}


.inputBox {
  margin-top: 20px;
  display: flex;
  width: 100%;
  max-width: 600px;
  border: 1px solid #ccc;
  padding: 5px;
  box-sizing: border-box;
  align-items: center;
}

.inputTextArea {
  font-size: 16px;
  padding: 10px;
  flex: 1;
  border-radius: 5px;
  border: 0;
  min-height: 20px;
  resize: none;
  width: 85%;
  box-sizing: border-box;
}

.paste-button {
  width: 15%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
}

.paste-button:hover {
  background-color: #0056b3;
}

.button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.imagePreview {
  max-width: 100%;
  max-height: 300px;
  margin-top: 20px;
}


@media (max-width: 768px) {


  .section_action {
    width: 200px; /* Fixed width */
    height: 150px; /* Fixed height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    text-align: center;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
    border: 5px solid var(--border-color);
    margin-top: 0;
    margin-bottom: 0;
  }
  
  
  .section_action h2 {
    font-size: 14px;
    margin: 0;
    margin-bottom: 20px; /* Gap between h2 and p */
    color: var(--text-primary-color);
  }
  
  .section_action p {
    font-size: 10px;
    margin: 0;
    text-align: center; /* Ensure text is center-aligned horizontally */
    color: var(--text-secondary-color);
  
  }

  .paste-button {
    width: 15%;
    padding: 5px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: center;
    font-size: 8px;
  }

}
