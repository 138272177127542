/* SalesPopup.css */

body, .popup-overlay, .popup-content {
    margin: 0;
    padding: 0;
  }



.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: #fff;
    padding: 0;
    margin: 0;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    position: relative;
    padding: 20px;

    margin-left: 20px;
    margin-right: 20px;

}

.remove-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background:  var(--text-primary-color);
    border: none;
    color: #fff;
    font-size: 18px;
    width: 30px;
    height: 30px;
    border-radius: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
.popup-content .form-group {
    position: relative;
    margin-bottom: 20px;
    padding: 0;
}

.popup-content input {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background: transparent;
    font-size: 16px;
    box-sizing: border-box; /* Ensure padding is included in the width calculation */
}

.popup-content input:focus + label,
.popup-content input:not(:placeholder-shown) + label {
    top: -10px;
    left: 0;
    font-size: 12px;
    color: var(--primary-color);
    background: #fff;
    padding: 0 4px;
}

.popup-content label {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 16px;
    color: #999;
    pointer-events: none;
    transition: 0.2s ease all;
}


