
.verdict-container  {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  height: auto; /* Make it full height */
  min-height: 20vw;
  box-sizing: border-box;
  font-size: 10pt;
  font-family: Arial;
  font-style: normal;
  margin-left: 30px;


}


.pos-verdict {
  flex: 1;
  padding-right: 100px;
  /* border: 1px solid var(--border-color); */
  margin: 0;
  min-height: 500px;
}

  
  h1 {
    font-size: 24px;
    color: #333;
  }
  
  h2 {
    font-size: 18px;
    margin-top: 20px;
    color: #555;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .verdict-bar {
    width: 100%;
    margin-top: 20px;
    height: 20px;
    border-radius: 10px;
    background: linear-gradient(to right, green, yellow, orange, red);
    position: relative;
  }
  
  .verdict-bar-fill {
    height: 100%;
    border-radius: 10px;
    background-color: transparent;
    position: absolute;
  }
  
  .verdict-bar-fill::after {
    content: '';
    height: 100%;
    width: 0;
    background-color: #666;
    border-radius: 10px;
  }
  
  .verdict-bar-fill[data-score="low"]::after {
    width: 25%;
    background-color: green;
  }
  
  .verdict-bar-fill[data-score="medium"]::after {
    width: 50%;
    background-color: yellow;
  }
  
  .verdict-bar-fill[data-score="high"]::after {
    width: 75%;
    background-color: orange;
  }
  
  .verdict-bar-fill[data-score="very-high"]::after {
    width: 100%;
    background-color: red;
  }
  
  .score-marker {
    position: absolute;
    top: -10px; /* Adjust to position above the bar */
    width: 5px;
    height: 30px;
    background-color: black;
    transform: translateX(-50%);
    /* Ensure the marker is stacked above other elements */
    z-index: 1;
  }
  
  .score-marker::before {
    content: '';
    position: absolute;
    top: -1px; /* Adjust to place the hat on top of the marker */
    left: 50%;
    width: 10px; /* Width of the hat */
    height: 5px; /* Height of the hat */
    background-color: black;
    border-radius: 2px; /* Optional: for rounded corners */
    transform: translateX(-50%);
  }
  .score-marker::after {
    content: attr(data-score);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: black;
  }

 
  
  .report-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .report-section {
    margin-bottom: 20px;
  }
  
  .report-options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .channel-button {
    background-color: #f0f0f0;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .channel-button:hover {
    background-color: #e0e0e0;
  }
  
  .channel-button.selected {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  .next-button,
  .submit-button {
    background-color: var(--button-bg-primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .next-button:hover,
  .submit-button:hover {
    background-color: var(--button-bg-secondary-color);
  }
  
  .report-detail,
  .report-message {
    margin-bottom: 20px;
  }
  
  .report-detail label,
  .report-message p {
    display: block;
    margin-bottom: 8px;
  }
  
  input,
  textarea {
    width: 80%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
  }
  
  textarea {
    resize: vertical;
    min-height: 80px;
  }
  

  @media (max-width: 768px) {  
    .verdict-container {
      flex-direction: column; /* Stack elements vertically for smaller screens */
      margin: 0;
      padding-bottom: 100px;
    }
  
    .pos-verdict {
      min-height: auto;
      padding-right: 0;

    }
  
  }